.ProjectCard{
    position: relative;
    max-width: 40vw;
    max-height: 40vw;
    width: 22em;
    height: 22em;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: none;
    transition: .1s;
}

.ProjectCard:hover{
    transform: scale(1.03);
}

.ProjectCard img{
    min-width: 100%;
    min-height: 100%;
    transition: .2s;
}

.ProjectTechs{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 5px;
    top: 0;
    right:0;
    padding: 10px;
    z-index: 998;
    height: calc(1*(2em * 1.4) + 5px);
    overflow: hidden;
    transform: translateZ(2000px);
}

.ProjectDescription{
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #121212;
    color: rgb(224, 224, 224);
    box-sizing: border-box;
    transition: .1s;
    z-index: 998;
    overflow-wrap: break-word;
}

.ProjectDescription:hover{
    opacity: 1;
}

.ProjectDescription h2{
    font-size: 1.4em;
    padding: 10px;
    color: rgb(242, 242, 242);
    border-bottom: 2px solid rgb(224, 224, 224);
}

.ProjectDescription h3{
    padding: 0px;
    font-size: 1em;
    font-weight: 600;
}

.TechLogo{
    width: 1.4em;
    height: 1.4em;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 100%;/* 
    background-color:rgb(255, 30, 30); */
    background-color:#121212;
    color: rgb(238, 238, 238);
}
.TechLogo h1{
    font-size: .6em;
}
