.ListContainer{
    padding-top: 12em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
@media only screen and (max-width: 500px) {
    .ListContainer{
        padding-top: 5px;
        padding-bottom: 100px;
    }
    
}