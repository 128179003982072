.info-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    padding: 20px;
}
.info-text{
    display: flex;
    flex-direction: column;
    max-width: 50em;
    gap: 10px;
}
canvas{
    border-radius: 100%;
    pointer-events: none;
}
.info-text h1{
    padding: 0px;
    margin: 0;
    font-size: 3em;
}
.changingText{
    font-size: 2.3em !important;
    padding-bottom: 5px !important;
    color: rgb(255, 30, 30);
}
.info-text h2{
    padding: 0px;
    margin: 0;
    font-size: 1.3em;
    color: rgb(214, 214, 214);
}
.scene-container{
    overflow: hidden;
}

@media only screen and (max-width: 800px) {
    .info-container{
        flex-direction: column-reverse;
        text-align: center;
        padding-bottom: 10em;
    }
    canvas{
        width: 90vw !important;
        max-width: 70vh !important;
        height: 90vw !important;
        max-height: 70vh !important;
    }
    .scene-container{
        width: 90vw !important;
        max-width: 70vh !important;
        height: 90vw !important;
        max-height: 70vh !important;
    }

}