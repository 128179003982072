.ProjectModal{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vh;
    background-color: #1f1f1f;
    z-index: 999;
}
.ModalHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 5em;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #121212;
    color: rgb(224, 224, 224);
}
.ModalHeader svg{
    font-size: 2.5em;
    cursor: pointer;
}
.ModalHeader svg:hover{
    transform: scale(1.05);
}
h1,h2{
    margin: 0;
    padding: 0;
}
.HeaderInfo h1{
    font-weight: 600;
}
.HeaderInfo h2{
    font-size: 1em;
    font-weight: 500;
}
.HeaderInfo h1:first-letter {
    text-transform: uppercase;
}
.ModalInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #ddd;
}
.ModalInfo h1{
    font-size: 2.5em;
    font-weight: 600;
}
.ModalInfo h2{
    word-wrap: break-word;
    padding-top: 10px;
    font-weight: 500;
    max-width: 40em;
}
.ModalInfo h1:first-letter {
    text-transform: uppercase;
}