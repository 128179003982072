body{
  background-color:#1f1f1f;
  height: 100vh;
}

#root{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container{
  min-height: 100vh;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-x: hidden;
}
@media only screen and (max-width: 500px) {

}